.document-container {
  width: 100%;
  height: 98%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  background-color: rgb(231, 231, 231);
  padding: 10px 40px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.document-page {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-top: 30px;
}

.document-page-count {
  display: flex;
  justify-content: center;
  margin-top: 1px;
  color: black;
  padding-left: 15px;
  background-color: rgb(248, 248, 248);
}

.document-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  margin-left: 20px;
  width: 200px;
  z-index: 10;
  height: 40px;
  padding: 5px;
  gap: 10px;
  background-color: rgba(192, 192, 192, 0.9);
  border-radius: 20px;
}

.document-toolbar-input {
  text-align: center;
  outline: none;
  width: 50px;
  background-color: transparent;
  pointer-events: none;
}

.document-toolbar-btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
}
.document-toolbar-btn:hover {
  background-color: rgba(116, 116, 116, 0.7);
  color: white;
}/*# sourceMappingURL=PDFViewer.css.map */