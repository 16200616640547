.ALM-container {
  width: 100%;
  padding: 20px 20px 0px 20px;
}

.ALM-title {
  margin-bottom: 30px;
}

.ALM-row {
  display: flex;
  margin-top: 10px;
}

.ALM-field {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
}

.ALM-subheading {
  width: 130px;
  margin-top: 3px;
}

.ALM-textarea {
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: rgba(239, 239, 239, 0.3);
  cursor: default;
  border-radius: 10px;
  height: 200px;
  resize: none;
  overflow: auto;
  padding-left: 4px;
}

.ALM-footer {
  display: flex;
  justify-content: flex-end;
}

.ALM-close-btn {
  margin-top: 30px;
  background-color: rgb(173, 148, 66);
  color: white;
  border-radius: 25px;
  padding: 5px 20px;
  border: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: rgb(7, 124, 130);
  }
}
