.container {
  display: flex;
  width: 100%;
  padding: 40px 50px 90px 50px;
  box-sizing: border-box;
  height: 90vh;
}

.img-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
  transition: 1.5s;
  margin: 0 0.5%;
  cursor: pointer;
}

.img-box-img {
  width: 150%;
  height: calc(100% - 10vh);
  object-fit: cover;
  transition: 1.5s;
}

.img-box:hover {
  flex: 1 1 20%;
}

.img-box:hover .img-box-img {
  opacity: 0.8;
  width: 100%;
}

.img-box-logo {
  width: 16rem;
  height: auto;
}

.gpt {
  width: 15rem;
  height: auto;
}

.transcribe-logo {
  width: 15rem;
  height: auto;
}
