.footer-section {
  justify-content: space-between;
  color: white;
  padding: 10px 10px;
  position: sticky;
  bottom: 0;
  background-color: #077c82;
  z-index: 100;
}

.footer-link {
  color: white;
  position: relative;
  text-decoration: none;
}
.footer-link::after {
  background: none repeat scroll 0 0 transparent;
  bottom: -5px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: white;
  transition: width 0.5s ease 0s, left 0.5s ease 0s;
  width: 0;
}
.footer-link:hover {
  color: white;
}
.footer-link:hover::after {
  width: 100%;
  left: 0;
}

.footer-section p {
  margin: 0;
}

.footer-section img {
  height: 25px;
}

@media (max-width: 576px) {
  .footer-section img {
    height: 15px;
  }
  .footer-section a,
  .footer-section p {
    font-size: 10px;
  }
}/*# sourceMappingURL=Footer.css.map */