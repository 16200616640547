@import "../../named_variable";

.navbar {
  background-color: white;
  /* z-index: 20; */
  z-index: 1;
  .nav-link {
    color: black;
    background-color: transparent;
    font-weight: bold;
    border: 0;

    &.active {
      color: $primary-color;
    }
    &:hover {
      color: $primary-color;
    }
  }
  img {
    height: 70px;
    width: 220px;
    object-fit: contain;
  }
  .navbar-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  .navbar-collapse {
    margin-left: 15px;
  }
}

// .nav-link {
//   color: black;
//   font-weight: 500;
//   font-size: large;
// }

.user-container {
  background-color: transparent;
  color: $primary-color;
  border: 0;
  font-weight: bold;
  &:hover {
    background-color: transparent;
    color: $primary-color;
  }
}
.button-dropdown {
  color: $primary-color;
}
.drop-menu-container {
  // width: 100%;
  top: 30px;
  min-width: 100%;
  margin-top: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 0;
}
.dropdown-item-customize {
  &:active {
    background-color: $primary-color;
  }
}
.active {
  color: #077c82;
}
@media (max-width: 576px) {
  .navbar {
    padding: 0;
    img {
      height: 25px;
    }
  }
}

.authnav {
  border-bottom: 1px solid #e7e7e7;
  margin: 0 10px;
}
