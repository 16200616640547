.openqcmodal-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tick-img {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}

.up-container {
  width: 100%;
  height: 94%;
  // background-color: green;
  display: flex;
  flex-direction: column;
  // justify-content: center;
}

.up-form {
  display: flex;
  width: 100%;
  gap: 20px;
}

.up-left-fields {
  display: flex;
  width: 90%;
  height: 80%;
  justify-content: space-evenly;
  flex-direction: column;
}

.up-dragger-container {
  width: 100%;
  height: 100%;
}

.up-dragger {
  width: 100%;
}

.up-title {
  margin-bottom: 30px;
}

.outer-up-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: auto;
}

.retry-btn {
  gap: 5px;
}

.submit-ticket-btn {
  background-color: #077c82;
  gap: 5px;

  &:hover {
    background-color: #ad9442;
  }
}

.close-btn-fail {
  background-color: rgb(150, 150, 150);
  gap: 5px;

  &:hover {
    background-color: rgb(124, 124, 124);
  }
}
