@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-documenteditor/styles/material.css";
a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  padding: 0;
  margin: 0;
}

.app {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  max-height: -webkit-fill-available;
  justify-content: space-between;
}

.full-flex {
  flex: 1;
}

.body-container {
  flex: 1;
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  /* height: 100%; */
  /* width: 100%; */
  /* background-color: red; */
}

.border-shadow-container {
  padding: 20px;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 20px;
  background-color: #ad9442;
  border: 0;
  max-width: -moz-fit-content;
  max-width: fit-content;
  justify-self: flex-end;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition-property: background-color;
  transition: 250ms linear;
}

.button:hover {
  background-color: #077c82;
}

.button.warning {
  color: black;
  background-color: rgba(53, 53, 53, 0.185);
}
.button.warning:hover {
  color: white;
  background-color: rgb(158, 158, 158);
}

.button.circle {
  border-radius: 50%;
  padding: 10px;
}

.button.min-width {
  min-width: 300px;
}

.button.tab {
  padding: 10px 20px;
  margin-bottom: 10px;
}
.button.tab.not-shown {
  visibility: hidden;
}

.button.action {
  padding: 5px 10px;
  border-radius: 5px;
}

.icon-action {
  font-size: 21px;
  cursor: pointer;
  color: black;
}

button:disabled {
  background-color: rgb(158, 158, 158);
  cursor: pointer;
  pointer-events: none;
}

.search-input {
  max-width: 450px;
}

.link {
  text-decoration: none;
  cursor: pointer;
  color: #077c82;
  transition: -webkit-text-decoration 250ms linear;
  transition: text-decoration 250ms linear;
  transition: text-decoration 250ms linear, -webkit-text-decoration 250ms linear;
}

.link:hover {
  color: #077c82;
  text-decoration: underline;
}

.form-container {
  width: 30%;
  height: 100%;
  overflow: auto;
}

.responsive-border {
  border-left: 1px solid #e8e8e8;
}

.icon.lock {
  color: red;
}

@media only screen and (max-width: 768px) {
  .form-container {
    width: 100%;
    height: 40%;
    overflow: scroll;
  }
  .responsive-border {
    border-left: none;
    border-top: 1px solid #e8e8e8;
  }
}
.ant-tabs-content-holder,
.ant-tabs-content,
.ant-tabs-tabpane {
  height: 100%;
}

.ant-tabs-nav {
  margin: 0 !important;
}

.ant-select-dropdown {
  z-index: 2000;
}

.ant-form-item .ant-form-item-label {
  text-align: left;
  width: auto;
  margin: 0;
}

.ant-upload-wrapper {
  width: 100%;
}

.plus-icon {
  background-color: #ad9442;
  border-radius: 50%;
}

.pdf-viewer-container {
  height: 100%;
}

.visibility {
  visibility: visible;
}

.invisibility {
  visibility: hidden;
}

.text-bold {
  font-weight: bold;
}/*# sourceMappingURL=App.css.map */