@import "../../named_variable";

.footer-section {
  // display: flex;
  // align-items: center;
  justify-content: space-between;
  color: white;
  padding: 10px 10px;
  position: sticky;
  bottom: 0;
  background-color: $primary-color;
  z-index: 100;
}

.footer-link {
  color: white;
  position: relative;
  text-decoration: none;
  &::after {
    background: none repeat scroll 0 0 transparent;
    bottom: -5px;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: white;
    transition: width 0.5s ease 0s, left 0.5s ease 0s;
    width: 0;
  }
  &:hover {
    color: white;
    &::after {
      width: 100%;
      left: 0;
    }
  }
}

.footer-section p {
  margin: 0;
}
.footer-section img {
  height: 25px;
}

@media (max-width: 576px) {
  .footer-section {
    img {
      height: 15px;
    }
    a,
    p {
      font-size: 10px;
    }
  }
}
