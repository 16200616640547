.QT-page-container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: space-between;
  padding: 0px 10px;
  margin-bottom: auto;
  margin-top: 10px;
  gap: 10px;
  overflow: auto;
}

.QT-source-container {
  width: 50%;
}

.QT-target-container {
  width: 50%;
}

.QT-copy-icon {
  z-index: 3;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.QT-swap-icon {
  transform: rotate(90deg);
  margin-right: 30px;
  margin-left: -21px;
  margin-top: -5px;
  padding: 7px 10px;
  background-color: #f5f5f5;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.QT-swap-icon:hover {
  background-color: #e2e2e2;
}

.QT-target-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.QT-target-translate-btn {
  background-color: #077c82;
  margin-left: 5px;
}
.QT-target-translate-btn:hover {
  background-color: #ad9442;
}

.QT-copied-text {
  z-index: 3;
  font-size: 13px;
  margin-top: 1px;
}

.QT-target-footer {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.QT-nav-left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.QT-nav-left h5 {
  margin-bottom: 4px;
}/*# sourceMappingURL=QuickTextTranslatePage.css.map */