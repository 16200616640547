.status-dropdown {
  display: flex;
  align-items: center;
  border: 1px solid grey;
  border-radius: 5px;
}
.status-dropdown .status-title {
  display: flex;
  padding: 0px 10px;
  height: 100%;
  background-color: #077c82;
  align-items: center;
  color: white;
}
.status-dropdown .ant-select {
  height: 100%;
  width: 150px;
}
.status-dropdown .ant-select .ant-select-selector {
  border: none;
}

.container-wrapper {
  display: flex;
  width: 100%;
  position: relative;
  gap: 30px;
  overflow: hidden;
  flex: 1;
}

.download-options {
  text-wrap: nowrap;
  align-items: center;
  gap: 10px;
  display: flex;
  width: 600px;
  padding: 5px 10px;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 10px;
}

.download-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 20px;
  background-color: #ad9442;
  border: 0;
  max-width: -moz-fit-content;
  max-width: fit-content;
  justify-self: flex-end;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition-property: background-color;
  transition: 250ms linear;
}
.download-btn:hover {
  background-color: #077c82;
}

.transfer-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 20px;
  background-color: #077c82;
  border: 0;
  max-width: -moz-fit-content;
  max-width: fit-content;
  justify-self: flex-end;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition-property: background-color;
  transition: 250ms linear;
}
.transfer-btn:hover {
  background-color: #ad9442;
}

.download-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.download-modal-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 15px 5px 14px;
  background-color: #ad9442;
  border: 0;
  max-width: -moz-fit-content;
  max-width: fit-content;
  justify-self: flex-end;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition-property: background-color;
  transition: 250ms linear;
}
.download-modal-btn:hover {
  background-color: #077c82;
}

.download-options-container {
  display: flex;
  width: 370px;
  align-items: center;
  justify-content: flex-end;
  gap: 7px;
  margin-top: -1px;
}

.download-input-doc-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #077c82;
  padding: 5px 12px;
  border-radius: 5px;
  gap: 4px;
}
.download-input-doc-btn:hover {
  background-color: #ad9442;
}
.download-input-doc-btn:hover .download-input-doc-icon {
  filter: invert(99%);
}

.download-input-doc-icon {
  width: 21px;
  height: auto;
  margin-top: 2px;
  filter: invert(63%) sepia(83%) saturate(456%) hue-rotate(5deg) brightness(99%) contrast(86%);
  transition: 250ms linear;
}/*# sourceMappingURL=TranslationDetailPage.css.map */