.tab-btn {
  text-align: center;
  font-size: 16px;
  white-space: nowrap;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.02);
  z-index: 1000;
  border: 1px solid #e8e8e8;
  border-bottom: 0px;
  cursor: pointer;
  padding: 5px 20px;
  height: 50px;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  width: 150px;
}

.active-tab {
  height: calc(100% + 1px);
  background-color: white;
  color: #077c82;
}

.tab-bar-container {
  width: 100%;
  display: flex;
  height: 60px;
  border-bottom: 1px solid #e8e8e8;
  z-index: 1000;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 10px;
  gap: 90px;
}

.tab-bar-inner {
  display: flex;
  align-items: center;
}

.search-bar {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-right: 100px;
  z-index: 10;
}

.action-right-container > .search-bar > .ant-input-group-wrapper {
  max-width: 800px;
}

.auto-refresh-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  text-wrap: nowrap;
  padding-right: 10px;
}
