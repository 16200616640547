.navbar {
  background-color: white;
  /* z-index: 20; */
  z-index: 1;
}
.navbar .nav-link {
  color: black;
  background-color: transparent;
  font-weight: bold;
  border: 0;
}
.navbar .nav-link.active {
  color: #077c82;
}
.navbar .nav-link:hover {
  color: #077c82;
}
.navbar img {
  height: 70px;
  width: 220px;
  -o-object-fit: contain;
     object-fit: contain;
}
.navbar .navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}
.navbar .navbar-collapse {
  margin-left: 15px;
}

.user-container {
  background-color: transparent;
  color: #077c82;
  border: 0;
  font-weight: bold;
}
.user-container:hover {
  background-color: transparent;
  color: #077c82;
}

.button-dropdown {
  color: #077c82;
}

.drop-menu-container {
  top: 30px;
  min-width: 100%;
  margin-top: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 0;
}

.dropdown-item-customize:active {
  background-color: #077c82;
}

.active {
  color: #077c82;
}

@media (max-width: 576px) {
  .navbar {
    padding: 0;
  }
  .navbar img {
    height: 25px;
  }
}
.authnav {
  border-bottom: 1px solid #e7e7e7;
  margin: 0 10px;
}/*# sourceMappingURL=Nav.css.map */